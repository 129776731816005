<template>
  <div class="canna_start_pageZone">
    <div class="canna_login_zone">

      <!-- Zona de Restablecer -->
      <template v-if="currentView==='main'">

        <div id="login_container_frame" class="login_container_frame">

          <div class="main_title">Restablecer contraseña</div>
          <div class="main_subtitle spacerRowTop32">Crea y confirma tu nueva contraseña</div>
          <div class="canna_formZone spacerRowTop32">

              <ValidationObserver ref="formResetPX" v-slot="{ handleSubmit, valid, passed }">
                <q-form
                  ref="myFormRPX"
                  @submit.prevent="handleSubmit(onDataReset)"
                  @reset="onResetSubmit"
                  class="canna_forma_zona"
                  style=""
                >

                  <!-- Campo de password -->
                  <ValidationProvider rules="required|cannaTextPasswordAlt|emojiFree|min:8|max:12" name="newPassWord" :immediate="false" v-slot="{ errors, invalid, validated  }">
                    <div class="canna_forma_campo_container">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >Nueva contraseña</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        :type="(screen_passA===true) ? 'password' : 'text'"
                        name="newPassWord"
                        v-model="resetData.password"
                        hint=""
                        :dense="true"
                        :label="(resetData.password==='')?'Escribe tu nueva contraseña':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('newPassWord','Contraseña').replace('El campo','La') : ''"
                        :disabled="processingData"
                        @blur="pasox_onBlurNewPass('newpass', $event)"
                      >
                        <template v-slot:append>
                          <div class="inputLabelAction noselect action_item" @click.stop="screen_passA = !screen_passA"
                            v-show="(resetData.password.length>=1) ? true : false "
                          >
                            {{(screen_passA===true) ? 'MOSTRAR' : 'OCULTAR'}}  <!-- <q-icon name="r_star" /> -->
                          </div>
                        </template>
                      </q-input>
                    </div>
                  </ValidationProvider>
                  <!-- Campo de confirmacion de password -->
                  <ValidationProvider rules="required|cannaTextPasswordConfAlt:@newPassWord|emojiFree|min:8|max:12" name="confpassword" :immediate="false" v-slot="{ errors, invalid, validated  }">
                    <div class="canna_forma_campo_container" style="margin-top: 20px;">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >Confirma tu nueva contraseña</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        :type="(screen_passB===true) ? 'password' : 'text'"
                        name="confpassword"
                        v-model="resetData.confpassword"
                        hint=""
                        :dense="true"
                        :label="(resetData.confpassword==='')?'Escribe nuevamente tu contraseña':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('confpassword','Confirmacion de contraseña').replace('El campo','La') : ''"
                        :disabled="processingData"
                        @blur="pasox_onBlurNewPass('confPass', $event)"
                      >
                        <template v-slot:append>
                          <div class="inputLabelAction noselect action_item" @click.stop="screen_passB = !screen_passB"
                            v-show="(resetData.confpassword.length>=1) ? true : false "
                          >
                            {{(screen_passB===true) ? 'MOSTRAR' : 'OCULTAR'}}  <!-- <q-icon name="r_star" /> -->
                          </div>
                        </template>
                      </q-input>
                    </div>
                  </ValidationProvider>

                  <div class="canna_forma_campo_container spacerRowTop24">
                    <div  style="font-size: 11px; line-height: 1; color: rgba(0, 0, 0, 0.54); text-align: left;" >
                      <div>
                        <div>
                            Tu contraseña debe tener entre 8 y 12 caracteres. Para que sea segura debes incluir en ella números, 
                            letras y signos de puntuación como: &#10095; - _ . ! * $ % &#38; / # ? ) = ¿ + ¡ ( &#10094;
                        </div>
                      </div>
                    </div>
                  </div>

                  <template v-if="(frmMainErrors.formResetPX !== '')">
                    <div class="canna_frmMainErrorZone spacerRowTop28" style="display: flex;flex-direction: row;align-items: center;">
                      <div style="margin-right:10px;">
                        <img src="images/login_warning.png"/>
                      </div>
                      <div style="flex:1;" v-html="frmMainErrors.formResetPX"></div>
                    </div>
                  </template>

                  <div class="buttonZone" style="margin-top: 60px;">
                    <q-btn unelevated  label="Restablecer contraseña" type="submit" class="buttonSave" color="app_mainBtn" :disabled="processingData" 
                      :style="'width:100%;'"
                      :disable="((passed || valid))? false : true"
                    />
                  </div>

                </q-form>
              </ValidationObserver>

          </div>

        </div>

      </template>

      
      <!-- Zona de login despues de restablecer --> 
      <template v-else>

        <div id="login_container_frame" class="login_container_frame loginReset_container_frame">

          <div class="main_title">
            Tu contraseña ha <template v-if="($q.screen.lt.sm)"><br></template> sido restablecida
          </div>
          <div class="main_subtitle spacerRowTop32">
            Para iniciar sesión ingresa tu dirección de correo <template v-if="($q.screen.lt.sm)"><br></template> electrónico
            <template v-if="($q.screen.gt.xs)"><br></template> y la nueva contraseña que creaste.
          </div>
          <div class="canna_formZone spacerRowTop32">

              <ValidationObserver ref="formCannaLog" v-slot="{ handleSubmit, valid, passed }">

                <q-form
                  ref="myFormCannaLog"
                  @submit.prevent="handleSubmit(onLoginSubmit)"
                  @reset="onLoginReset"
                  class="canna_forma_zona"
                  style=""
                >
                  <!-- Campo de email -->
                  <ValidationProvider 
                    ref="fldz_email"
                    rules="required|email|emojiFree|max:255" name="Email" :immediate="false" v-slot="{ errors, invalid, validated  }">
                    <div class="canna_forma_campo_container">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >Correo electrónico</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        name="nombre"
                        type="email"
                        v-model.trim="loginData.email"
                        hint=""
                        :dense="true"
                        :label="(loginData.email==='')?'ejemplo@email.com':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('Email','Correo electrónico').replace('El campo','El') : ''"
                        :disabled="processingData"
                        @input="val => { loginData.email = ('' + val).trim()} "
                      />
                    </div>
                  </ValidationProvider>
                  <!-- Campo de password -->
                  <ValidationProvider 
                    ref="fldz_password"
                    rules="required|cannaTextPassword|emojiFree|min:8|max:12" 
                    name="password" :immediate="false" v-slot="{ errors, invalid, validated  }"
                  >
                    <div class="canna_forma_campo_container">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >Contraseña</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        :type="(screen_pass===true) ? 'password' : 'text'"
                        name="password"
                        v-model="loginData.password"
                        hint=""
                        :dense="true"
                        :label="(loginData.password==='')?'Escribe tu contraseña':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('PassWord','Contraseña').replace('El campo','La') : ''"
                        :disabled="processingData"
                      >
                        <template v-slot:append>
                          <div class="inputLabelAction noselect action_item" @click.stop="screen_pass = !screen_pass"
                            v-show="(loginData.password.length>=1) ? true : false "
                          >
                            {{(screen_pass===true) ? 'MOSTRAR' : 'OCULTAR'}}  <!-- <q-icon name="r_star" /> -->
                          </div>
                        </template>
                      </q-input>
                    </div>
                  </ValidationProvider>

                  <template v-if="(frmMainErrors.formCannaLog !== '')">
                    <div class="canna_frmMainErrorZone spacerRowTop24" style="display: flex;flex-direction: row;align-items: center;">
                      <div style="margin-right:10px;">
                        <img src="images/login_warning.png"/>
                      </div>
                      <div style="flex:1;" v-html="frmMainErrors.formCannaLog"></div>
                    </div>
                  </template>

                  <div class="buttonZone">
                    <q-btn unelevated  label="Iniciar sesión" type="submit" class="buttonSave" color="app_mainBtn" :disabled="processingData" 
                      :style="'width:100%;'"
                      :disable="((passed || valid))? false : true"
                    />
                  </div>

                </q-form>

              </ValidationObserver>

          </div>
        </div>

      </template>

    </div>

    <q-inner-loading :showing="loading_Process" class="app_innerLoader" style="top: -51px;z-index: 9000;">
      <q-spinner-orbit size="100px" color="indigo" />
    </q-inner-loading>

  </div>
</template>
<script>
import { requestPack } from './../../boot/axios' // APP code 
import { canna_cookieMethods } from './../../boot/__cannav2_app'

import logOut_Mixin from './../../mixins/requestMix.js'
import myplatform_Mixin from './../../mixins/platformMix.js'
import helperBase_Mixin from './../../mixins/helperBase.js'

export default {
  name: 'canna_loginreset_screen',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin
  ],
  components:{

  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      zloadingData: false,
      loading_Process: false,
      processingData: false, 
      currentView: 'main', // 'main' ||  'loginview'
      screen_pass: true, //para controlar el mostrar ocultar en el input
      screen_passA: true, //para controlar el mostrar ocultar en el input
      screen_passB: true, //para controlar el mostrar ocultar en el input
      loginData: { 
        email: '',
        password: ''
      },
      resetData: { 
        password: '',
        confpassword: ''
      },
      paramsRP:{ 
        key:null,
        user:null
      },
      frmMainErrors:{
        formCannaLog: '',
        formResetPX: ''
      },
      frmMainRefs:{
        formCannaLog: [
          {fieldNameAPi: 'email', fieldNameForm: 'email' },
          {fieldNameAPi: 'password', fieldNameForm: 'password' }
        ],
        formResetPX: [
        ]
      }
    }
  },
  computed: {

  },
  watch: {
    currentView : function (newVal, oldVal) { 
      // WIP
      if (newVal==='loginview'){ 
        if (this.$refs.fldz_email) {
          this.$refs.fldz_email.reset()
        }
        if (this.$refs.fldz_password) {
          this.$refs.fldz_password.reset()
        }
      }
    },
    'loginData.password': function (newVal, oldVal) {
      if (newVal!==oldVal){ 
        if (newVal==='' || newVal===null){
          this.screen_pass = true
        }
      }
    },
    'resetData.password': function (newVal, oldVal) {
      if (newVal!==oldVal){ 
        if (newVal==='' || newVal===null){
          this.screen_passA = true
        }
      }
    },
    'resetData.confpassword': function (newVal, oldVal) {
      if (newVal!==oldVal){ 
        if (newVal==='' || newVal===null){
          this.screen_passB = true
        }
      }
    }
  },
  methods: {
    ...requestPack,
    ...canna_cookieMethods,
    test () { 
      this.$cannaDebug('-- loginResetscreen -- test')
    },
    pasox_onBlurNewPass ( nameField, event){
      if (this.resetData.password!== '' && this.resetData.confpassword!== ''){ 
        this.frmMainErrors.formResetPX = (this.resetData.password ===  this.resetData.confpassword) ? '' : 'Las contraseñas ingresadas no coinciden.'
      } else {
        this.frmMainErrors.formResetPX = ''
      }
      this.$forceUpdate()
    },
    async onDataReset () {
      this.$cannaDebug('-- loginResetscreen -- onDataReset')
      var test = 11
      var mpModel = this.getModelRequest('passRenew')
      var reqRes = { action: [], profile: [] }
      var reqErr = { action: 0, profile: 0 }
      // ------------------
      this.loading_Process = true
      // ------------------
      var myResetConfig = this.createAxiosConfig ('post', mpModel, {
        delimiters:[ this.paramsRP.user],
        urlmixData:{
          key: this.paramsRP.key,
          password: this.resetData.password
        },
      }, false, null)
      // ------------------
      await this.doRequestByPromise(this.$axios, 'resetLogin', reqRes.action, myResetConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        // handleError
        this.ex_handlerErrorFormNoSessionRequest (reqRes.action[0], reqErr.action, this.$refs.formResetPX, 'formResetPX',  this.frmMainRefs.formResetPX , 'formResetPX', true, 'warning')
        this.loading_Process = false
        return false
      }
      await this._waitRequestProc(1000)
      // ------------------
      // Todo bien no hubo errores

      //----------------
      // Removemos de la cookie el objeto restorePass ya que ya fue utilizado 
      var test = this.cannack_destroyCookie( this.$q.cookies, 'CannaV2PublicResetVz' )
      this.$cannaDebug('-- PV2 -- Main Layout -- goToCloseSession --- ' + test)

      //Actualizamos el Store de reset
      this.$store.commit('myapp/setAppResetAccess', { action_done:true }) // Solo 2 props 
      await this._waitRequestProc(500)

      // var myCookieInfo = this.cannack_getCookieData() // imposible que sea nulo 
      // var newCookieInfo = JSON.parse(JSON.stringify(myCookieInfo))
      // delete newCookieInfo.restorePass 

      // await this._waitRequestProc(1000)

      // var testCks = this.cannack_setCookieData (newCookieInfo)
      // this.$cannaDebug('-- resetPass -- cleanKeyanduser -- ' + testCks)
      //----------------

      this.currentView = 'loginview'
      setTimeout(() => {

        if (this.$refs.fldz_email) {
          this.$refs.fldz_email.reset()
        }

        if (this.$refs.fldz_password) {
          this.$refs.fldz_password.reset()
        }
        
        this.loading_Process = false
      }, 1000)
    },
    onResetSubmit () {

    },
    onLoginReset () {

    },
    async onLoginSubmit () {
      this.$cannaDebug('-- loginscreen -- onLoginSubmit')
      var test = 11 
      var mloginModel = this.getModelRequest('_userlogin')
      var mpModel = this.getModelRequest('userProfile')
      var reqRes = { login: [], profile: [] }
      var reqErr = { login: 0, profile: 0 }
      // ------------------
      this.loading_Process = true
      // ------------------
      var myLogConfig = this.createAxiosConfig ('post', mloginModel, { 
        useData: true ,
        data: {
          grant_type: 'password',
          username: '' + this.loginData.email,
          password: '' + this.loginData.password
        } 
      }, false, null)
      // ------------------
      await this.doRequestByPromise(this.$axios, 'userlogin', reqRes.login, myLogConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.login[reqRes.login.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.login += 1
        }
      })
      if (reqErr.login > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        // handleError
        this.ex_handlerErrorFormNoSessionRequest (reqRes.login[0], reqErr.login, this.$refs.formCannaLog, 'formCannaLog',  this.frmMainRefs.formCannaLog , 'formCannaLog', false)
        this.loading_Process = false
        return false
      }
      await this._waitRequestProc(100)
      // ------------------
      var loginData = reqRes.login[0].data.data
      var myToken = loginData.access_token
      this.$store.commit('myapp/setloginData', { token: myToken }) //setloginData
      this.$store.commit('myapp/setAppUserData', { access_token: myToken })
      await this._waitRequestProc(100)
      // ------------------
      // Hasta aqui estamos bien y obtenemos los datos de profile 
      // Hasta aqui estamos bien y obtenemos los datos de profile 
      var myProfileConfig = this.createAxiosConfig ('get', mpModel, { test: 11, expand:'experienciaLaboral,userPerfilLaboralFiltro' }, true, null)
      await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profile, myProfileConfig).then((resx) => {
        // console.log ('Action -- Carga de usuario -- GET ejecutado')
        var tmpresponseData = reqRes.profile[reqRes.profile.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.profile += 1
        }
      })

      // var myProfileConfig = this.createAxiosConfig ('get', mpModel, { test: 11 }, true, null)
      // await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profile, myProfileConfig).then((resx) => {
      //   // console.log ('Action -- Carga de Clientes -- GET ejecutado')
      //   var tmpresponseData = reqRes.profile[reqRes.profile.length - 1]
      //   if (tmpresponseData.type !== 'success') {
      //     reqErr.profile += 1
      //   }
      // })

      if (reqErr.profile > 0) { // Si hay error de server, session o algo redireccionamos..!
        this.$cannaDebug('-- loginscreen -- Error al cargar el profile -- ')
        this.loading_Process = false
        return false
      }
      var profileData = reqRes.profile[0].data.data
      this.$store.commit('myapp/setMainState',{ appUserProfile: profileData })
      await this._waitRequestProc(200)
      // ------------------
      // Actualizamos o creamos la cookie principal de la app 
      // update cookie DATA
      var dataObject = { 
        logIn:true,
        user: profileData.id,
        token: myToken
      }
      //-----------------
      var testCks = null 
      var mainCKExist = this.$q.cookies.has('CannaV2Public')
      if (mainCKExist === true){
        var testCDx = this.cannack_destroyCookie( this.$q.cookies, 'CannaV2Public' )
        await this._waitRequestProc(400)
      }
      // else {
      //    testCks = this.cannack_createCookie (this.$q.cookies, 'CannaV2Public', dataObject, true)
      // }
      await this._waitRequestProc(200)
      var testCks = this.cannack_createCookie (this.$q.cookies, 'CannaV2Public', dataObject, true)
      // var testCks = this.cannack_setCookieData (this.$q.cookies, dataObject) Se reemplaza para inicializar la cookie 
      this.$cannaApiService.$cannaAxios.defaults.headers.common.Authorization = `Bearer ${loginData.access_token}`
      //this.$cannaDebug('-- loginscreen -- ck created && updated -- ' + testCks)
      this.$cannaDebug('-- loginscreen -- ck created && updated 0002 -- ' + testCks)

      // var testCks = this.cannack_setCookieData (dataObject)
      // this.$cannaDebug('-- loginscreen -- ckupdate -- ' + testCks)
      test = 77
      // ------------------
      this.loading_Process = false
      this.$forceUpdate()
      // Verificamos el estatus de la persona 
      var newRuta = ''
      if (profileData.status >=10){ //12 
        newRuta= '/dashboard'
      } else { // Entonces el estatus es 10 u 11 
        newRuta = '/' //'/nuevacuenta'
      }
      this.$router.push(newRuta)
      return true
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- loginResetscreen -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- loginResetscreen -- created')
    this.loading_Process = true
    this.currentView = 'main'
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- loginResetscreen --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- loginResetscreen -- mounted')
    //Revisar si aplican parametros de Reset y si existe la cookie ETC
    var x_hasErrores = false

    var checkVerify = this.$store.state.myapp.appResetAccess
    var doExistVerifyCookie = this.$q.cookies.has('CannaV2PublicResetVz')
    if (checkVerify.action === true && checkVerify.action_done === false && checkVerify.data!==null && doExistVerifyCookie===true){
      var myDVx = checkVerify.data 
      if (myDVx instanceof Object && myDVx.constructor === Object) {
        var valorUSER = checkVerify.data.user_id
        var valorLLAVE = checkVerify.data.key

        var testA = (/[A-Za-z0-9_-]{8}/).test(valorLLAVE)
        var testB = (/\d{10}/).test(valorLLAVE)
        var testC = (/[0-9]*/).test(valorUSER)
        var globalTest = (testA && testB && testC)

        if (globalTest === false ){ 
          x_hasErrores = true // A Redireccionar a path ROOT 
        } else { 
          this.paramsRP.key = valorLLAVE
          this.paramsRP.user = valorUSER

          this.$forceUpdate()
          this.loading_Process = false
          return true
        }
      } else { 
          x_hasErrores = true // A Redireccionar a path ROOT 
      }
    } else { 
        x_hasErrores = true  // A Redireccionar a path ROOT 
    }

    if (x_hasErrores===true) { //NO hay de otra opcion 

      var msgAlert = 'Error en parametros de enlace para restaurar acceso, posibles causas:\n ' + 
      '1.-Los parametros expiraron o ya fueron utilizados\n ' + 
      '2.-Si copio el enlace verifique que sea correcto el enlace\n '
      '3.-Solicite restaurar acceso nuevamente'
      
      alert (msgAlert)
      this.$router.push('/') // WIP
      this.loading_Process = false
      return false

    }
    return true

    // // Checar la cooki si trae los params key user_id
    // var myCookieInfo = this.cannack_getCookieData() 
    // var cookieTest = null 
    // if (myCookieInfo === null ) {
    //   cookieTest = false 
    // } else { 
    //   if (myCookieInfo.hasOwnProperty('restorePass')) { 
    //     if (myCookieInfo.restorePass.hasOwnProperty('key') && myCookieInfo.restorePass.hasOwnProperty('user_id')) { 
    //       cookieTest = true 
    //     } else {
    //       cookieTest = false 
    //     }
    //   } else{
    //     cookieTest = false 
    //   }
    // }
    // if (cookieTest===false){ 
    //   alert ('no aplica by-Cookie TEST') 
    //   // this.$router.push('/') // WIP 
    //   this.loading_Process = false
    //   return false 
    // }
    // var testA = (/[A-Za-z0-9_-]{8}/).test(myCookieInfo.restorePass.key)
    // var testB = (/\d{10}/).test(myCookieInfo.restorePass.key)
    // var testC = (/[0-9]*/).test(myCookieInfo.restorePass.user_id)
    // var globalTest = (testA && testB && testC) 
    // if (globalTest === false ){ 
    //   alert ('no aplica  by-validation TEST') 
    //   // this.$router.push('/') // WIP 
    //   this.loading_Process = false
    //   return false 
    // }

    // this.paramsRP.key = myCookieInfo.restorePass.key
    // this.paramsRP.user = myCookieInfo.restorePass.user_id

    // this.loading_Process = false
    // return true

  },
  beforeUpdate () {
    // this.$cannaDebug('-- loginResetscreen -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- loginResetscreen -- updated')
  },
  activated () {
    this.$cannaDebug('-- loginResetscreen -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- loginResetscreen -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- loginResetscreen -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- loginResetscreen -- destroyed')
  }
}
</script>
